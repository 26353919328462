export default {
  text: 'white',
  text2: 'white',
  primary: 'black',
  secondary: 'black',
  background: 'black',
  backgroundSecondary: 'black',
  light: '#FFF',
  dark: '#141416'
}

export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      position: 'fixed',
      padding: ['1rem', '1rem', '2rem 1rem', '', '2rem 1rem', '2rem'],
      '.smallNavMenu': {
        color: 'light'
      }
    },

    '.containerScrolled': {
      background: 'black',
      position: 'fixed',
      border: 'none',
      padding: ['1rem', '1rem', '1rem', '1rem'],
      '.smallNavMenu': {
        color: 'white',
        '.contact': {
          ml: '1.5rem',
          a: {
            variant: 'buttons.secondary',
            padding: '1rem 2rem',
            letterSpacing: '0px'
          }
        },
        '.navItemDropdownMenu': {
          backgroundColor: 'black',
          '.navItem': {
            color: 'white'
          }
        }
      }
    },

    '.logoLocationContainer': {
      margin: ['', '', '', '0rem auto 0rem 0rem'],
      padding: '0rem',
      position: ['static'],
      '.logo': {
        maxWidth: ['230px', '', '260px', '280px', '', '320px'],
        filter: 'unset',
        img: {
          p: '0rem',
          filter: 'unset'
        }
      },
      '.logoScrolled': {
        p: '0rem',
        m: '0rem',
        // filter: 'brightness(0) invert(1)',
        img: {
          p: '0rem',
          maxWidth: '200px',
          objectFit: 'contain'
        }
      }
    },
    '.navItemLink svg': {
      display: 'none'
    },

    '.smallNavMenu': {
      transition: 'all ease-in-out 0.8s',
      '.navItem': {
        margin: ['', '', '', '0rem 0.75rem'],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        a: {
          fontSize: ['1.25rem', '', '', '', '1.1rem', '1.1rem'],
          letterSpacing: ['0px', '', '', '', '1px', '1.5px'],
          ':hover': {
            color: 'white',
            textDecoration: 'underline'
          }
        }
      },
      '.dropdownOpen': {
        a: {
          color: 'primary'
        }
      },
      '.navItemDropdownMenu': {
        width: 'fit-content',
        backgroundColor: 'transparent',
        '.navItem': {
          color: 'black'
        }
      },
      '.contact': {
        ml: '1.5rem',
        a: {
          variant: 'buttons.primary',
          padding: '1rem 2rem',
          letterSpacing: '0px',
          border: '1px solid white!important'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      border: 'none',
      borderRadius: '5px',
      padding: '0.8rem',
      display: ['', '', '', 'none'],
      mr: '0rem',
      div: {
        backgroundColor: 'white',
        height: '2px'
      }
    },
    '.hamburgerOpen > div': {
      backgroundColor: 'white'
    },
    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'transparent',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: '1.5rem',
          letterSpacing: '0px',
          fontWeight: 'bold',

          ':hover': {
            color: 'primary'
          }
        }
      }
    },
    '.navMenuOpen': {
      '::before': {
        content: "'Chapters'",
        padding: '0.5rem',
        mb: '1.5rem',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        opacity: '0.5',
        borderBottom: '1px solid',
        textTransform: 'uppercase'
      },
      color: 'white',
      backgroundColor: 'transparent',
      padding: ['', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          ':hover': {
            color: 'primary'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },

      '.navItemDropdown': {
        display: 'flex',
        flexDirection: 'column'
      },

      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent',
        textShadow: 'unset',
        '.navItem': {
          ml: '1rem'
        }
      }
    },

    '.phoneSocialContainer': {
      mt: '4rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      opacity: '0.5',

      '> li': {
        width: 'fit-content',
        fontWeight: 'bold',

        a: {
          fontSize: '20px'
        },
        '.fa-phone': {
          mr: '1rem'
        },
        '.socialLink': {
          svg: {
            width: '26px',
            height: '26px'
          }
        }
      }
    },
    '.navBlock': {
      width: ['15%', '40%', '50%', '60%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      height: '100vh',
      position: 'fixed'
    },
    '.navBlockOpen': {
      position: 'fixed',
      backdropFilter: 'blur(1000042px)',
      backgroundImage: 'url(https://img.freepik.com/free-vector/soft-blurred-background_1034-273.jpg?w=2000)',
      backgroundColor: '#000000',
      opacity: 0.98,
      backgroundSize: 'cover',
      filter: 'brightness(0.1)'
    },

    '.navMenuLogo, .smallNavHeading': {
      display: 'none'
    }
  },

  footer: {
    color: 'white',
    '.logo': {
      // filter: 'brightness(0) invert(1)'
    },
    '.address': {
      display: 'none'
    },
    '.gonationLogo': {
      a: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
      }
    },
    '.innerHoursContainer': {
      display: 'none'
    },
    '.socialContainer svg': {
      border: 'solid 1px',
      borderRadius: '50%',
      padding: '0.25rem',
      height: '45px',
      width: '45px',
      ':hover': {
        color: 'primary'
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  sideBySide1: {
    flexWrap: 'wrap',
    mt: '30vh',
    padding: '0rem',
    // padding: ['2rem 1rem', '2rem', '10vh 2rem', '20vh 2.5vw', '25vh 2.5vw'],
    clipPath: 'polygon(0 15%, 100% 0%, 100% 85%, 0% 100%)',
    '.content': {
      mt: '30vh',
      mb: ['0rem', '', '15vh'],
      padding: ['2rem 1rem', '2rem ', '1rem 3rem 1rem 3rem', '3rem 3rem 3rem 3rem', '4rem 4rem 4rem 4rem'],
      alignItems: 'flex-start',
      '> *': {
        mb: '2rem'
      }
    },
    '.image': {
      height: '100%'
    },

    '.title': { variant: 'text.title', fontSize: ['2rem', '2.5rem', '2.5rem', '2.75rem', '3rem'] },
    '.subtitle': { variant: 'text.subtitle', color: 'white' },
    '.text': { variant: 'text.text', color: 'inherit', maxWidth: '700px', fontSize: ['1.1rem', '', '1.25rem'] }
  },

  sideBySide2: {
    flexWrap: 'wrap',
    padding: ['2rem 1rem', '2rem', '10vh 2rem', '10vh 2.5vw', '15vh 2.5vw'],
    maxWidth: '1100px',
    margin: 'auto',
    '.content': {
      padding: ['2rem 1rem', '2rem ', '1rem 3rem 1rem 3rem', '3rem 3rem 3rem 3rem', '4rem 4rem 4rem 4rem'],
      alignItems: 'flex-start',
      '> *': {
        mb: '2rem'
      }
    },
    '.title': { variant: 'text.title', fontSize: ['2rem', '2.5rem', '2.5rem', '2.75rem', '3rem'] },
    '.subtitle': { variant: 'text.subtitle', color: 'white' },
    '.text': { variant: 'text.text', color: 'inherit' }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundColor: 'white',
    height: '100vh',
    color: 'text',
    '.section': {
      pt: ['90px', '', '', '150px'],
      maxWidth: '1000px',
      ml: ['', '', '', '5%'],
      mr: 'auto',
      '> div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '2rem',
        textAlign: 'left'
      }
    },
    '.title': { variant: 'text.title', fontSize: ['3.5rem', '4rem', '5rem', '6rem', '7rem'], order: '1' },
    '.subtitle': { variant: 'text.subtitle', fontSize: ['1.75rem', '2rem', '2.5rem', '2.75rem', '3rem'], order: '2' },
    '.text': { variant: 'text.text', fontSize: ['1.25rem', '1.25rem', '1.5rem', '1.75rem', '1.75rem'], order: '3' },
    '.linksContainer': { order: '4' },

    'svg.slick-arrow': {
      top: 'unset',
      bottom: '0rem',
      backgroundColor: 'transparent',
      border: 'solid 1px',
      color: 'white',
      height: '35px',
      width: '35px',
      padding: '0.25rem'
    },
    'svg.slick-next': {
      right: 'unset',
      left: '4.5rem'
    },
    'svg.slick-prev': {
      left: '1rem'
    }
  },

  homepageTagline: {
    '.section': {
      width: '100%',
      maxWidth: 'unset',
      textAlign: 'left',
      padding: ['0rem', '', '5vh 5vw'],
      alignItems: 'flex-start'
    },
    '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '3rem', '4rem', '5rem'], order: '1' },
    '.subtitle': { variant: 'text.subtitle', fontSize: ['1.75rem', '2rem', '2.5rem', '2.75rem', '3rem'], order: '2' },
    '.text': {
      variant: 'text.text',
      fontSize: ['1rem', '1rem', '1.25rem', '1.25rem', '1.25rem'],
      order: '3',
      maxWidth: '900px'
    },
    '.linksContainer': {
      order: '4',
      '.ctaButton': {
        backgroundColor: 'primary',
        borderColor: 'primary',
        ':hover': {
          backgroundColor: '#202020',
          borderColor: '#202020'
        }
      }
    }
  },

  events: {
    '.container': {
      p: '0rem',
      mb: '1rem',
      '> .title': {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'primary',
        color: 'white',
        p: '1rem',
        justifyContent: 'center',
        alignItems: 'center'
      },
      color: 'white',
      '.eventDate': {
        color: 'white'
      },
      span: {
        color: 'white'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'primary',
    color: 'white',
    mt: '3rem'
  },
  homepageBooking: {
    variant: 'customVariants.sideBySide2'
  },
  homepageEvents: {
    '.eventItemBtn': {
      padding: '0.5rem',
      width: 'fit-content',
      backgroundColor: 'transparent',
      color: 'text'
    },
    '.eventDate': {
      color: 'white'
    },
    mt: '-5rem',
    zIndex: '1'
  },

  homepageGallery: {
    backgroundColor: 'black',
    padding: '20vh 0rem 20vh',
    color: 'white',
    textAlign: 'center',
    '.section': {
      maxWidth: '1000px',
      padding: '0.5rem'
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3rem', '3.5rem'],
      color: 'inherit'
    },
    '.text': {
      order: '3',
      mb: '2rem',
      fontSize: ['1.1rem', '1.1rem', '1.2rem', '1.3rem'],
      '*': {
        color: 'white',
        opacity: '1'
      }
    },
    '.linksContainer': {
      order: '4',
      '.ctaButton': {
        backgroundColor: 'primary',
        borderColor: 'primary',
        border: '1px solid white',
        ':hover': {
          backgroundColor: '#202020',
          borderColor: '#202020'
        }
      }
    }
  },

  homepageContact: {
    variant: 'customVariants.homepageGallery',
    padding: '20vh 0rem 20vh',
    color: 'white'
  },

  aboutPageContent: {
    variant: 'customVariants.homepageGallery',
    padding: '20vh 0rem 20vh',
    color: 'white',
    '.linksContainer': {
      order: -1
    }
  },

  // ? ====================
  // ? =====  Lights  =====
  // ? ====================

  lightsHero: {
    mt: ['83px', '', '116px', '116px'],
    variant: 'customVariants.homepageVideo',
    padding: '0rem',
    height: ['', '', '', 'calc(100vh - 116px)'],
    'div.videoContainer': {
      borderRadius: '0px'
    },
    '.linksContainer': {
      '.ctaButton': {
        backgroundColor: 'primary',
        borderColor: 'primary',
        border: '1px solid white',
        ':hover': {
          backgroundColor: '#202020',
          borderColor: '#202020'
        }
      }
    }
  },

  lights1: {
    '.videoContainer': {
      width: ['', '', '40%', '', '40%', '50%'],
      iframe: {
        height: ['100vw', '', '30vw']
      }
    },

    '.content': {
      width: ['', '', '60%', '', '60%', '50%'],
      order: ['', '', '2'],
      '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '2.75rem', '', '3rem'] },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    }
  },

  lights2: {
    variant: 'customVariants.homepageVideo',
    padding: '0rem',
    'div.videoContainer': {
      borderRadius: '0px'
    }
  },

  lights3: {
    padding: '1rem',
    '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '2.75rem', '', '3rem'] },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },

    '.slick-initialized .slick-arrow': {
      backgroundcolor: 'white',
      color: 'white',
      opacity: '0.7'
    }
  },

  lights4: {
    variant: 'customVariants.lights3',
    minHeight: '500px',
    padding: '0rem 1rem 0rem',
    '.content': {
      order: ['', '', '2']
    }
  },

  lightsGallery: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.albumTitle, .albumName': {
      display: 'none'
    }
  },

  // ? ====================
  // ? =====  Camera  =====
  // ? ====================

  cameraHero: {
    mt: ['83px', '', '116px', '116px'],
    height: ['', '', '', 'calc(100vh - 116px)'],
    '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '2.75rem', '', '3rem'] },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },
    '.slick-initialized .slick-arrow': {
      backgroundcolor: 'white',
      color: 'white',
      top: '40%',
      width: '35px',
      height: '35px',
      opacity: '0.7'
    }
  },

  camera1: {
    variant: 'customVariants.homepageTagline'
  },

  cameraCardsTitle: {
    width: '100%',
    maxWidth: '1600px',
    mt: '10vh',
    px: ['1.5rem', '2rem', '3rem', '6rem'],
    '.subtitle': {
      color: 'white'
    }
  },

  cameraPackages: {
    variant: 'customVariants.homepageOurWork',
    pt: ['0rem', '0rem', '0rem', '0rem'],
    '.contentBoxes': {
      margin: '0rem'
    },
    'div.box': {
      '.frontCard': {
        backgroundcolor: 'white',
        borderRadius: '10px'
      },

      ul: {
        li: {
          mb: '0.25rem'
        }
      }
    }
  },
  cameraGallery: {},

  // ? ====================
  // ? =====  DJS  =====
  // ? ====================

  djHero: {
    variant: 'customVariants.lightsHero'
  },

  dj1: {
    variant: 'customVariants.homepageTagline'
  },

  djCardsTitle: {
    variant: 'customVariants.cameraCardsTitle',
    mt: '0rem'
  },

  djCollections: {
    p: ['1rem', '1rem 1.5rem', '1.5rem 2.5rem', '2rem 5.5rem'],
    '.contentBoxes': {
      justifyContent: 'flex-start',
      padding: []
    },
    '.box': {
      maxWidth: '900px',
      width: '100%',
      mb: '2rem',
      '.title': {
        variant: 'text.title',
        padding: '0rem 0rem 0rem 1rem',
        borderLeft: '5px solid',
        borderColor: 'primary',
        mb: '1.5rem'
      },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    }
  },

  djVideo: {
    flexDirection: ['column', 'column', 'column', 'column'],

    '.content': {
      width: '100%',
      alignItems: 'flex-start',
      justifyContent: 'center',
      px: ['1.5rem', '2rem', '3rem', '6rem'],
      '.title': {
        variant: 'text.title',
        fontSize: ['2.2rem', '2.5rem', '3rem', '4rem', '4rem']
      },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    },

    '.videoContainer': {
      width: '100%',
      height: ['60vw', '60vw', '', '600px'],
      iframe: {
        maxWidth: '1100px',
        px: ['1.5rem', '2rem', '3rem', '6rem']
      }
    }
  },

  djClients: {
    py: '5vh',
    '.textContent': {
      mb: '10vh',
      px: ['1.5rem', '2rem', '3rem', '6rem']
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['2.2rem', '2.5rem', '3rem', '4rem', '4rem']
    },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },
    '.image': {
      height: '80px',
      padding: '1rem',
      width: 'auto',
      objectFit: 'contain'
    }
  },

  djPackages: {
    variant: 'customVariants.cameraPackages'
  },
  djDisclaimer: {
    py: '0rem',
    px: ['1.5rem', '2rem', '3rem', '6rem'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    '.title': {
      fontSize: ['1rem', '', '1.1rem']
    },
    '.subtitle': {
      fontSize: ['0.8rem', '', '0.9rem']
    }
  },

  djGallery: {
    variant: 'customVariants.lightsGallery'
  },

  // ? =========================
  // ? =====  Photobooth  ======
  // ? =========================

  photoboothHero: {
    variant: 'customVariants.lightsHero'
  },

  photoboothDescription: {
    display: 'flex',
    textAlign: 'left',
    px: ['1.5rem', '2rem', '3rem', '6rem'],

    '.section': {
      margin: '0rem',
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['2.2rem', '2.5rem', '3remrem', '4rem', '4rem'],
      textAlign: 'left'
    },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
      ul: {
        columns: '2',
        li: {
          '::before': {
            content: 'none'
          }
        }
      }
    },
    '.linksContainer': {
      mt: '3rem',
      '.ctaButton': {
        variant: 'buttons.primary',
        backgroundColor: 'primary',
        borderColor: 'primary',
        border: '1px solid white',
        ':hover': {
          backgroundColor: 'black',
          borderColor: 'black'
        }
      }
    }
  },

  photoboothPackagesTitle: {
    variant: 'customVariants.cameraCardsTitle',
    px: ['1.5rem', '2rem', '3rem', '6rem']
  },

  photoboothGallery: {
    variant: 'customVariants.gallery',
    '.albumName': {
      display: 'none'
    }
  },

  // ? =============================
  // ? =====  Cinematography  ======
  // ? =============================

  cinematographyHero: {
    variant: 'customVariants.photoboothHero'
  },

  cinematographyDescription: {
    variant: 'customVariants.homepageTagline'
  },

  cinematographyPackagesTitle: {
    variant: 'customVariants.cameraCardsTitle',
    px: ['1.5rem', '2rem', '3rem', '6rem']
  },

  cinematographyPackages: {
    variant: 'customVariants.cameraPackages'
  },
  cinematographySlider: {
    // variant: 'customVariants.sideBySide1',
    padding: ['10vh 0rem 15vh', '', '15vh 0rem 25vh'],
    '.title': {
      variant: 'text.title',
      fontSize: ['2.2rem', '2.5rem', '3rem', '4rem', '4rem']
    },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },
    '> .mainTextContainer': {
      display: 'none'
    },
    backgroundColor: 'backgroundSecondary',
    color: 'white',
    '.slideBox': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.textContent': {
        padding: '2rem',
        maxWidth: '800px'
      }
    },
    iframe: {
      width: ['100%', '', '', '800px'],
      height: ['60vw', '', '', '400px']
    },

    '.slick-dots': {
      bottom: '-35px'
    },
    '.slick-arrow': {
      position: 'absolute',
      border: 'solid 1px',
      width: '25px',
      height: '25px',
      top: '65%',
      padding: '0.3rem',
      color: 'white',
      bordercolor: 'white',
      ':hover': {
        borderColor: 'white'
      }
    },

    '.slick-next': {
      right: '0rem'
    }
  },

  // ? ======================
  // ? =====  Add ons  ======
  // ? ======================

  addOnsPagehero: {
    variant: 'customVariants.lightsHero',
    '.hero_content_container': {
      top: 'unset',
      bottom: '0rem',
      transform: 'unset',
      margin: '0rem'
    },
    img: {
      filter: 'brightness(0.6)'
    }
  },
  addOnsDescription: {
    variant: 'customVariants.homepageTagline',
    '.title': {
      variant: 'text.title',
      order: '1'
    },
    '.text': { variant: 'text.text', order: '3' }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactHero: {
    variant: 'customVariants.lightsHero'
  },

  contactDescription: {
    variant: 'customVariants.homepageTagline',
    '.title': {
      variant: 'text.title',
      mb: '3rem',
      order: '1'
    },
    '.subtitle': {
      color: 'white!important'
    },
    '.text': { variant: 'text.text', order: '3' }
  },

  contactForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    '.subtitle': {
      color: 'white!important'
    },
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ["''"],
      //   backgroundColor: ['rgb(18 1 1 / 70%)', '', 'rgb(18 1 1 / 87%)'],
      width: ['100%', '', '50%'],
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'text.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      bordercolor: 'white',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'light',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      border: '1px solid white',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  // ? ===================================
  // ? ====  Our Work Indivdual page  ====
  // ? ===================================

  ourWorkGallery: {
    mt: ['83px', '', '116px', '116px'],
    '.albumContainer': {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      '.albumName': {
        variant: 'text.title',
        writingMode: ['unset', '', 'vertical-lr'],
        p: '1rem'
      }
    }
  },

  ourWorkSection: {
    py: '1rem',
    '.box': {
      width: ['100%', '', '50%'],
      padding: ['1rem', '', '0% 5%'],
      '.title': { variant: 'text.title', mb: '2rem' },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    }
  },

  ourWorkVideo: {
    variant: 'customVariants.djVideo',
    'div.content': { py: '0rem' }
  }
}
